import React, {useRef, useEffect} from 'react'
import {gsap} from 'gsap'
import {motion} from "framer-motion";

import Hero from '../components/Hero'
import Header from '../components/Header'
import Works from '../components/Works'
import Footer from '../components/Footer'
import NightVideo from '../videos/Stars.mp4';
import ShopifyPartner from '../images/shopify-partner.jpg';

import {ScrollTrigger} from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

function Home() {

    let titleAnim = useRef(null)
    let titleAnim2 = useRef(null)
    let subtitleAnim = useRef(null)
    let tl = gsap.timeline()
    const LoadingTransition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

    useEffect(() => {

        tl.from(subtitleAnim, {
            y: 15,
            opacity: 0,
            delay: 1,
            duration: 1,
            ease: "power3.inOut"
            })
            .from(titleAnim, {
                y: 15,
                opacity: 0,
                delay: 0.5,
                duration: 1,
                ease: "power4.easeInOut"
            })
            .from(titleAnim2, {
                y: 15,
                opacity: 0,
                delay: 0.5,
                duration: 3,
                ease: "power0.easeInOut"
            })
            .from(".scroll-down", {
                y: 15,
                opacity: 0.2,
                duration: .5,
                ease: "power3.inOut"
            });
            

    }, [titleAnim, titleAnim2, subtitleAnim, tl]);

    return(
        <div>
            <motion.div 
                initial={{x:0}}
                animate={{x:'-100%'}}
                exit={{x:0}}
                transition={LoadingTransition}
                className="page-trans">
            </motion.div>
            <video 
                autoPlay
                playsInline
                muted
                loop
                style={{
                    position:'absolute',
                    width: "100%",
                    height: "100%",
                    objectFit:"cover",
                    zIndex: "-1"
                }}
            >
                <source src={NightVideo} type="video/mp4" />
            </video>
            <Header/>
            <Hero  mainImage={'/portfolio/FlippingRecords/mainImage.png'}>
                <h6 ref={el => subtitleAnim = el} className="sub-title">Welcome to</h6>
                <h1 ref={el => titleAnim = el} className="big-title">Constellation Island.</h1>
                <h5 ref={el => titleAnim2 = el} className="big-title">Shopify Partner & e-Commerce Agency.</h5>
            </ Hero>
            {/* <div style={{
                display:"flex",
                background:"white",
                justifyContent:"center"
            }}>
            <img src={ShopifyPartner} height="250px" alt="ShopifyPartner" />
            </div> */}
            <Works />
            <Footer />
        </div>
    )

}

export default Home