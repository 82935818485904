import React from 'react'
import {Link} from 'react-router-dom'

function Logo(){

    return(
        <div className="logo">
            <img alt="windytree" height="90px" src={process.env.PUBLIC_URL + '/tree-copyC.PNG'} /> 
            <Link to='/'><span>Constellation Island</span></Link>
        </div>
    )
}

export default Logo