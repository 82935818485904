import React from 'react'

function WorkProjectHero(props){

    return(
        <section style={{display:'flex', justifyContent:'center'}}className="section">
            <div className="container-fluid-small">
                <img src={props.mainImage} alt=""></img>
            </div>
        </section>
    )
}

export default WorkProjectHero