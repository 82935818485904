const Works = [
    {
        id: 1,
        name: 'Flipping Records',
        category: 'e-commerce',
        fixedCategory: 'e-commerce',
        image: 'assets/images/portfolio/FlippingRecords/mainImage.png',
        mainImage: 'assets/images/portfolio/FlippingRecords/mainImage.png',
        background: 'assets/images/portfolio/FlippingRecords/mtjoy.jpeg',
        headline: 'Vinyl Records Framed & Preserved as Art.',
        text: ['Everytime ipsum dolor sit amet cons ecte adip sicings elit. Ear poss pers piciatis enimes ame maiores ler dol res libero totam recusanda enim belemq est.', 'Ipsam voluptatem quia voluptas sit aspernatur autodit aut fugit sed quia consequuntur magniferro. Neque porro quisquam est qui dolor ipsum qui dolor sit amet belemeq consectetur adipisci velit sed qui.'],
        date: 'June 14, 2021',
        servicesLeft: ['Brand New Identity', 'Responsive UI/UX Design' , 'iOS & Android Development'],
        servicesRight: ['Design Brainstorming', 'Product & Market Research', 'Selling & Marketing Strategy'],
        imagesLeft: ['/portfolio/FlippingRecords/home.png', '/portfolio/FlippingRecords/designs.png'],
        imagesRight: ['/portfolio/FlippingRecords/collection.png', '/portfolio/FlippingRecords/product1.png']
    },
    {   
        id: 2,
        name: 'Delores',
        category: 'design',
        fixedCategory: 'design',
        image: '/assets/images/2.jpg',
        background: '/assets/images/single-project-hero-2.jpg',
        headline: 'A new premium experience for users.',
        text: ['Everytime ipsum dolor sit amet cons ecte adip sicings elit. Ear poss pers piciatis enimes ame maiores ler dol res libero totam recusanda enim belemq est.', 'Ipsam voluptatem quia voluptas sit aspernatur autodit aut fugit sed quia consequuntur magniferro. Neque porro quisquam est qui dolor ipsum qui dolor sit amet belemeq consectetur adipisci velit sed qui.'],
        date: 'November 25, 2020',
        servicesLeft: ['Brand New Identity', 'Responsive UI/UX Design' , 'iOS & Android Development'],
        servicesRight: ['Design Brainstorming', 'Product & Market Research', 'Selling & Marketing Strategy'],
        imagesLeft: ['project-2-1.jpg'],
        imagesRight: ['project-2-2.jpg']
     },
     {
        id: 3,
        name: 'Bitter Leaf',
        category: 'strategy',
        fixedCategory: 'strategy',
        image: '/assets/images/3.jpg',
        background: '/assets/images/single-project-hero-3.jpg',
        headline: 'A new premium experience for users.',
        text: ['Everytime ipsum dolor sit amet cons ecte adip sicings elit. Ear poss pers piciatis enimes ame maiores ler dol res libero totam recusanda enim belemq est.', 'Ipsam voluptatem quia voluptas sit aspernatur autodit aut fugit sed quia consequuntur magniferro. Neque porro quisquam est qui dolor ipsum qui dolor sit amet belemeq consectetur adipisci velit sed qui.'],
        date: 'November 29, 2020',
        servicesLeft: ['Brand New Identity', 'Responsive UI/UX Design' , 'iOS & Android Development'],
        servicesRight: ['Design Brainstorming', 'Product & Market Research', 'Selling & Marketing Strategy'],
        imagesLeft: ['project-3-1.jpg'],
        imagesRight: ['project-3-2.jpg']
     },
     {
        id: 4,
        name: 'Magic Mind',
        category: 'design',
        fixedCategory: 'design',
        image: '/assets/images/4.jpg',
        background: '/assets/images/single-project-hero-4.jpg',
        headline: 'A new premium experience for users.',
        text: ['Everytime ipsum dolor sit amet cons ecte adip sicings elit. Ear poss pers piciatis enimes ame maiores ler dol res libero totam recusanda enim belemq est.', 'Ipsam voluptatem quia voluptas sit aspernatur autodit aut fugit sed quia consequuntur magniferro. Neque porro quisquam est qui dolor ipsum qui dolor sit amet belemeq consectetur adipisci velit sed qui.'],
        date: 'December 3, 2020',
        servicesLeft: ['Brand New Identity', 'Responsive UI/UX Design' , 'iOS & Android Development'],
        servicesRight: ['Design Brainstorming', 'Product & Market Research', 'Selling & Marketing Strategy'],
        imagesLeft: ['project-4-2.jpg'],
        imagesRight: ['project-4-1.jpg']
     },
     {
        id: 5,
        name: 'Time',
        category: 'strategy',
        fixedCategory: 'strategy',
        image: '/assets/images/5.jpg',
        background: '/assets/images/single-project-hero-5.jpg',
        headline: 'A new premium experience for users.',
        text: ['Everytime ipsum dolor sit amet cons ecte adip sicings elit. Ear poss pers piciatis enimes ame maiores ler dol res libero totam recusanda enim belemq est.', 'Ipsam voluptatem quia voluptas sit aspernatur autodit aut fugit sed quia consequuntur magniferro. Neque porro quisquam est qui dolor ipsum qui dolor sit amet belemeq consectetur adipisci velit sed qui.'],
        date: 'December 16, 2020',
        servicesLeft: ['Brand New Identity', 'Responsive UI/UX Design' , 'iOS & Android Development'],
        servicesRight: ['Design Brainstorming', 'Product & Market Research', 'Selling & Marketing Strategy'],
        imagesLeft: ['project-5-1.jpg'],
        imagesRight: ['project-5-2.jpg']
     },
     {
        id: 6,
        name: 'Do Not Touch',
        category: 'strategy',
        fixedCategory: 'strategy',
        image: '/assets/images/6.jpg',
        background: '/assets/images/single-project-hero-6.jpg',
        headline: 'A new premium experience for users.',
        text: ['Everytime ipsum dolor sit amet cons ecte adip sicings elit. Ear poss pers piciatis enimes ame maiores ler dol res libero totam recusanda enim belemq est.', 'Ipsam voluptatem quia voluptas sit aspernatur autodit aut fugit sed quia consequuntur magniferro. Neque porro quisquam est qui dolor ipsum qui dolor sit amet belemeq consectetur adipisci velit sed qui.'],
        date: 'December 29, 2020',
        servicesLeft: ['Brand New Identity', 'Responsive UI/UX Design' , 'iOS & Android Development'],
        servicesRight: ['Design Brainstorming', 'Product & Market Research', 'Selling & Marketing Strategy'],
        imagesLeft: ['project-6-1.jpg'],
        imagesRight: ['project-6-2.jpg']
     }
]

export default Works