import React, {useRef, useState, useEffect} from 'react'
import {gsap} from 'gsap'

import WorkItem from '../elements/WorkItem'

import works from '../data/Works'

import {ScrollTrigger} from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

function Works() {

    gsap.from(".banner-text", {
        scrollTrigger: {
            trigger: ".banner-text",
            start: "top 80%",
            markers: true,
            toggleActions: "play none none none"
        },
        scrub: 1,
        duration:2,
        opacity: 0.04,
        ease: "Power4.easeIn"
    });


    gsap.from(".banner-text3", {
        scrollTrigger: {
            trigger: ".banner-text3",
            start: "top 80%",
            markers: false,
            toggleActions: "play none none none"
        },
        scrub: 1,
        delay:1,
        duration:2,
        opacity: 0.2,
        ease: "Power4.easeIn"
    
    });
    

    const [filter, setFilter] = useState('all')
    const [projects, setProjects] = useState([])

    useEffect(() => {
        setProjects(works)
    }, []);

    useEffect(() => {
        setProjects([])
        let worksUpdated = []
        for(let i=0; i<works.length; i++){
            if(filter !== 'all' && !works[i].category.includes('portfolio-hidden')){
                works[i].category = `${works[i].category} portfolio-hidden`
            }
            if(filter === 'all'){
                let mue = works[i].category.split(' ')
                works[i].category = mue[0]
            }
            if(works[i].category.includes(filter)){
                works[i].category = filter
            }
            worksUpdated.push(works[i])
        }
        setProjects(worksUpdated);
    }, [filter]);

    const worksFilters = ['all', 'design', 'branding', 'strategy', 'Store Setup & Theme Development', 'Digital & Social Marketing', 'Website Design & Branding', 'Content Strategy & Photography']
    const displayWorks = projects.map((item, i) => <WorkItem key={i} title={item.name} category={item.category} image={item.image} id={item.id}/>)

    return(
        <section id="portfolioSection" className="section">
           
        

            <div className="container-fluid-small">
                <div className="logoBanner">
                <img alt="windytree" height="100px" src={process.env.PUBLIC_URL + '/tree-copyC.PNG'} />  
                <div>
                {/* <h6 style={{paddingTop:"15px"}}>Constellation Island</h6>        */}
                </div>
                
                </div>
    
            <h4 className="banner-text big-title" style={{display:"flex", padding:"30vh 0"}}>We build custom development solutions for new brands on Shopify.</h4>

            </div>

            <div className="container-fluid-small">
    
            <h4 className="banner-text3 big-title">Projects.</h4>


                <nav className="nav portfolio-nav anim-bot">
                    {worksFilters.map((item, i) => <a key={i} className={`nav-item ${filter === item ? 'active' : null}`} onClick={() => setFilter(item)}>{item}</a>)}
                </nav>

                <div className="row portfolio-row anim-bot-big">
                    {displayWorks}
                </div>

            </div>
        </section>
    )

}

export default Works